import { Dialog } from '@headlessui/react'
import useTranslation from 'next-translate/useTranslation'
import { useEffect, useState } from 'react'

import { postNotification } from '@/api/notification/postNotificationPopup'
import { NAMESPACE_COMMON } from '@/res/namespaces'
import { INotification } from '@/types/Common'

interface Props {
  isOpen: boolean
  onClose: () => void
  notification: INotification
  getData: any
}

const NotificationPopup = ({
  isOpen,
  notification,
  onClose,
  getData,
}: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isViewed, setIsViewed] = useState(notification.is_viewed)
  const { t } = useTranslation(NAMESPACE_COMMON)

  useEffect(() => {
    const postData = async () => {
      if (!notification.is_viewed) {
        await postNotification(notification.id)
        setIsViewed(true)
        getData()
      }
    }
    postData()
    //eslint-disable-next-line
  }, [])

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <div className="fixed inset-0 z-50 bg-black/30" aria-hidden="true" />
      <Dialog.Panel className="mx-10">
        <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-scroll outline-none focus:outline-none">
          <div className="relative w-auto max-w-3xl px-3 mx-auto my-6">
            <div className="relative flex flex-col bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
              <div className="relative flex-auto p-6">
                <p className="pt-5 font-bold uppercase ">
                  {notification?.title}
                </p>
                <p className="pt-5 font-bold uppercase ">
                  {notification?.content}
                </p>
              </div>
              <div className="flex items-center justify-end p-6 border-t border-solid rounded-b border-slate-200">
                <button
                  className="px-6 py-2 mb-1 mr-1 text-sm font-bold text-red-500 uppercase transition-all duration-150 ease-linear outline-none background-transparent focus:outline-none"
                  type="button"
                  onClick={onClose}
                >
                  {t('close')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Dialog.Panel>
    </Dialog>
  )
}

export default NotificationPopup
