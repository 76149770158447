import Link from 'next/link'
import { useSession } from 'next-auth/react'
import useTranslation from 'next-translate/useTranslation'
import { useEffect, useState } from 'react'

import { getActiveOrder } from '@/api/order/getActiveOrder'
import Caption from '@/components/ui/Typo/Caption'
import { IconCart, IconMenu } from '@/res/icons'
import { logo } from '@/res/images'
import { NAMESPACE_COMMON } from '@/res/namespaces'
import { ROUTE_ORDER } from '@/res/routes'
import { usePopups } from '@/store/usePopups'
import { Popups } from '@/types/Popups'
import { numberWithSpaces } from '@/utils/numberWithSpaces'

import LanguageDropdown from '../LanguageDropdown'
import Notification from '../notification'
import PaymentPopup from '../Popups/PaymentPopup'
import ServicesPopover from './ServicesPopover'
import UserPopover from './UserPopover'

interface Props {
  toggleSidebar?: () => void
  notShowMenuIcon?: boolean
}

const Navbar: React.FC<Props> = ({ toggleSidebar, notShowMenuIcon }) => {
  const [scrolled, setScrolled] = useState(false)
  const { data } = useSession()
  const { currentPopup, openPopup, closePopup } = usePopups()
  const { t } = useTranslation(NAMESPACE_COMMON)
  const [ordersCount, setOrdersCount] = useState<null | number>(null)
  const handleScroll = () => {
    const offset = window.scrollY
    if (offset > 1) {
      setScrolled(true)
    } else {
      setScrolled(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  })

  const isLoggedIn = data?.user
  const isDcClient = data?.user?.role === 'dc_client'
  const isLegal = data?.user?.user_type === 'legal'

  useEffect(() => {
    const getCartItems = async () => {
      try {
        const res = await getActiveOrder(data?.user.accessToken as string)
        setOrdersCount(res.items.length)
      } catch {
        return
      }
    }

    if (isLoggedIn) {
      getCartItems()
    }

    //eslint-disable-next-line
  }, [isLoggedIn])

  return (
    <>
      {currentPopup === Popups.PAYMENT_POPUP ? (
        <PaymentPopup isOpen onClose={closePopup} />
      ) : null}
      <div
        className={`small:py-3 py-2 small:px-8  px-4 w-full flex items-center justify-between fixed bg-white z-50 transition-all top-0 
        ${scrolled ? 'shadow-md' : ''}`}
      >
        <Link href="/">
          <a className="flex items-center h-6 small:h-12">
            <img src={logo.src} className="h-full" alt="Uztelecom logo" />
          </a>
        </Link>
        <div className="flex items-center">
          {notShowMenuIcon ? null : (
            <div
              role="button"
              className="w-8 h-8 mr-1 small:mr-2 tablet:hidden"
              onClick={toggleSidebar}
            >
              <IconMenu className="w-full h-full text-primary" />
            </div>
          )}
          {isLoggedIn &&
          !isLegal &&
          !isDcClient &&
          (data?.user.balance || data?.user.balance === 0) ? (
            <button onClick={() => openPopup(Popups.PAYMENT_POPUP)}>
              <Caption
                weight="font-medium"
                className="tablet:block hidden mr-3 py-1.5 px-2 bg-slate-200/80 rounded-md hover:bg-slate-300/60 transition-colors"
                color="text-dark"
              >
                {t('balance')}:{' '}
                <span className="font-medium">
                  {numberWithSpaces(
                    parseFloat(data?.user.balance?.toString() || '0')
                  )}{' '}
                  {t('sum')}
                </span>
              </Caption>
            </button>
          ) : null}
          {isLoggedIn && ordersCount !== null && !isDcClient ? (
            <Link href={ROUTE_ORDER}>
              <a className="relative mr-3 small:mr-5">
                {ordersCount > 0 && (
                  <div className="absolute flex items-center justify-center w-5 h-5 text-xs font-medium text-white rounded-full -top-2 -right-2 bg-secondary">
                    {ordersCount}
                  </div>
                )}
                <IconCart className="text-primary w-7 h-7" />
              </a>
            </Link>
          ) : null}
          <div className="hidden small:block">
            {notShowMenuIcon && !isLoggedIn && !isDcClient ? null : (
              <Notification />
            )}
          </div>

          <div className="hidden mr-2 small:block">
            <LanguageDropdown />
          </div>

          {isDcClient ? null : <ServicesPopover />}
          {notShowMenuIcon && !isLoggedIn ? null : <UserPopover />}
        </div>
      </div>
    </>
  )
}

export default Navbar
