import { Dialog, Transition } from '@headlessui/react'
import { useSession } from 'next-auth/react'
import useTranslation from 'next-translate/useTranslation'
import { ChangeEvent, FormEvent, Fragment, useState } from 'react'

import { payWithAmount } from '@/api/order/payWithAmount'
import ButtonText from '@/components/ui/Typo/ButtonText'
import Caption from '@/components/ui/Typo/Caption'
import { IconClick, IconPayme } from '@/res/icons'
import { NAMESPACE_COMMON } from '@/res/namespaces'

import PaymentSelect from '../PaymentSelect'
import TextField from '../TextField'

interface Props {
  isOpen: boolean
  onClose: () => void
}

const paymentProviders = [
  {
    icon: IconPayme.src,
    title: 'Payme',
    value: 'payme',
  },
  {
    icon: IconClick.src,
    title: 'Click',
    value: 'click',
  },
]

export const summNumber = (x: string | number) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}

const PaymentPopup = ({ isOpen, onClose }: Props) => {
  const { data } = useSession()
  const { t } = useTranslation(NAMESPACE_COMMON)
  const [selectedProvider, setSelectedProvider] = useState(paymentProviders[0])
  const [amount, setAmount] = useState('')
  const [disabled, setDisabled] = useState(true)

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value
    if (val.startsWith('0') || val === '') {
      setAmount('')
      return
    }

    if (val.includes('e')) {
      return
    }

    const formatted = Number(val.replace(/\s+/g, ''))

    if (formatted >= 1000) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
    if (!isNaN(formatted)) {
      setAmount(summNumber(formatted))
    }
  }

  const onSubmit = async (e: FormEvent<HTMLButtonElement>) => {
    try {
      e.preventDefault()
      const token = data?.user.accessToken as string
      const _amout = parseFloat(amount.replace(/\s+/g, ''))
      const windowReference = window.open()
      const { link } = await payWithAmount(
        { provider: selectedProvider.value, amount: _amout },
        token
      )
      if (windowReference) {
        windowReference.location = link
      }
    } catch (err) {
      //eslint-disable-next-line
      console.log(err)
    } finally {
      onClose()
    }
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className="fixed inset-0 bg-black bg-opacity-25"
            role="button"
            onClick={onClose}
          />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md px-4 py-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl desktop:px-8 small:px-20">
                <PaymentSelect
                  paymentProviders={paymentProviders}
                  selectedProvider={selectedProvider}
                  setSelectedProvider={setSelectedProvider}
                />
                <div className="mt-6">
                  <TextField
                    wrapperClassName="!mb-3"
                    label={t('summ')}
                    inputProps={{
                      autoFocus: true,
                      onChange,
                      value: amount,
                    }}
                  />
                </div>
                <Caption color="text-gray3">{t('minimum_amount')}</Caption>

                <div className="flex justify-center mt-5 gap-x-3">
                  <button
                    type="submit"
                    onClick={onSubmit}
                    disabled={disabled}
                    className="px-8 py-3 rounded-lg outline-none disabled:opacity-80 bg-primary hover:opacity-90 active:scale-x-95"
                  >
                    <ButtonText size="small">{t('deposit')}</ButtonText>
                  </button>
                  <button
                    type="button"
                    onClick={onClose}
                    className="px-8 py-3 bg-yellow-500 rounded-lg outline-none hover:opacity-90 active:scale-x-95"
                  >
                    <ButtonText size="small">{t('cancel')}</ButtonText>
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default PaymentPopup
