import { Popover, Transition } from '@headlessui/react'
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'
import { Fragment, useEffect, useState } from 'react'

import { getServices } from '@/api/services/getServices'
import { Tooltip } from '@/components/ui/Tooltip'
import ButtonText from '@/components/ui/Typo/ButtonText'
import Caption from '@/components/ui/Typo/Caption'
import { IconServices } from '@/res/icons'
import { ROUTE_SERVICES } from '@/res/routes'
import { IService } from '@/types/Basic'

const ServicesPopover: React.FC = () => {
  const [services, setServices] = useState<IService[]>([])
  const { lang } = useTranslation()

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await getServices(lang)

        setServices(res.results)
      } catch (err) {
        return err
      }
    }

    getData()
  }, [lang])

  const tooltipContent = (
    <div className="flex flex-col justify-start">
      <Caption color="text-white" weight="font-normal" className="text-left">
        Сервисы
      </Caption>
    </div>
  )

  return (
    <div className="mr-1 h-11">
      <Popover className="relative">
        {() => (
          <>
            <Popover.Button className={`outline-none`}>
              <Tooltip message={tooltipContent}>
                <div
                  title="Сервисы"
                  className="p-2 rounded-full hover:bg-gray-100 cursor-pointer transition-colors"
                >
                  <IconServices className="text-gray-500 w-7 h-7" />
                </div>
              </Tooltip>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute right-0 z-10 -mt-1.5 w-max max-w-md border border-gray-100 rounded-lg">
                <div className="overflow-hidden rounded-lg shadow-lg outline-none">
                  <div className="bg-white">
                    <div className="px-5 pt-7 pb-3 border-b border-gray-200 grid grid-cols-2 gap-3">
                      {services.map(
                        ({ icon, id, name, slug, is_on_premise, url }) => {
                          return is_on_premise ? (
                            <Link href={`${ROUTE_SERVICES}/${slug}`} key={id}>
                              <a>
                                <div className="flex justify-center items-center flex-col p-2 hover:bg-gray-100 rounded-md transition-colors cursor-pointer">
                                  <div className="w-12 h-12 flex items-center justify-center">
                                    <img
                                      src={icon}
                                      alt={name}
                                      className="w-full h-full rounded"
                                    />
                                  </div>
                                  <ButtonText
                                    color="text-dark"
                                    size="small"
                                    className="mt-2 text-center"
                                  >
                                    {name}
                                  </ButtonText>
                                </div>
                              </a>
                            </Link>
                          ) : (
                            <a href={url} key={id}>
                              <div className="flex justify-center items-center flex-col p-2 hover:bg-gray-100 rounded-md transition-colors cursor-pointer">
                                <div className="w-12 h-12 flex items-center justify-center">
                                  <img
                                    src={icon}
                                    alt={name}
                                    className="w-full h-full rounded"
                                  />
                                </div>
                                <ButtonText
                                  color="text-dark"
                                  size="small"
                                  className="mt-2 text-center"
                                >
                                  {name}
                                </ButtonText>
                              </div>
                            </a>
                          )
                        }
                      )}
                    </div>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  )
}

export default ServicesPopover
