import { setUserAvatar } from '@/api/user/setUserAvatar'
import { IconCamera } from '@/res/icons'
import { toBase64 } from '@/utils/fileToBase64'

interface Props {
  avatar: string
  size?: 'small' | 'large'
}

const Avatar: React.FC<Props> = ({ avatar, size = 'large' }) => {
  return (
    <label className="relative cursor-pointer group" htmlFor="avatar">
      <input
        onChange={async (event) => {
          const file = event.target.files ? event.target.files[0] : null

          if (file) {
            const avatar = await toBase64(file)

            await setUserAvatar({ avatar })
            window.location.reload()
          }
        }}
        id="avatar"
        type="file"
        className="hidden"
      />
      <img
        src={`${process.env.NEXT_PUBLIC_MEDIA_BASE_URL}${avatar}`}
        alt=""
        className={`rounded-full ${
          size === 'large' ? 'w-24 h-24' : 'w-16 h-16'
        }`}
      />
      <img
        src={IconCamera.src}
        alt="camera"
        className={`absolute bottom-0 left-1/2 -translate-x-1/2 opacity-0 group-hover:opacity-100 transition-colors ${
          size === 'large' ? 'h-8 w-8' : 'h-6 w-6'
        }`}
      />
    </label>
  )
}

export default Avatar
