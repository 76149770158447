import create from 'zustand'

import { Popups } from '@/types/Popups'

interface IUsePopup {
  currentPopup: Popups | null
  openPopup: (p: Popups) => void
  closePopup: () => void
}

export const usePopups = create<IUsePopup>((set) => ({
  currentPopup: null,
  openPopup: (p) => {
    set({ currentPopup: p })
  },
  closePopup: () => set({ currentPopup: null }),
}))
