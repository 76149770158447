import { ReactNode } from 'react'

interface Props {
  message: string | ReactNode
  children: ReactNode
}

export const Tooltip: React.FC<Props> = ({ message, children }) => {
  return (
    <div className="relative flex flex-col items-center group outline-none">
      {children}
      <div className="absolute top-10 mt-4 right-0 flex-col items-center w-max hidden group-hover:flex">
        <div className="relative z-10 p-2 bg-gray-600 shadow-lg rounded-md">
          {message}
        </div>
      </div>
    </div>
  )
}
