import { API_GET_ACTIVE_ORDER } from '@/api/apiRoutes'
import api from '@/lib/api'
import { IOrder } from '@/types/Order'
import { getHeaderToken } from '@/utils/getHeaderToken'

export const getActiveOrder = async (token: string) => {
  try {
    const { data } = await api.get<IOrder>(API_GET_ACTIVE_ORDER, {
      headers: getHeaderToken(token),
    })

    return data
  } catch (err) {
    return Promise.reject(err)
  }
}
