import api from '@/lib/api'
import { getHeaderToken } from '@/utils/getHeaderToken'

import { API_USER_BALANCE_RECHARGE } from '../apiRoutes'

interface Body {
  provider: string
  amount: number
}

interface Response {
  link: string
}

export const payWithAmount = async (body: Body, token: string) => {
  try {
    const { data } = await api.post<Response>(API_USER_BALANCE_RECHARGE, body, {
      headers: getHeaderToken(token),
    })

    return data
  } catch (err) {
    return Promise.reject(err)
  }
}
