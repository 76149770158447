import { Listbox, Transition } from '@headlessui/react'
import { Fragment } from 'react'

interface IPaymentProvider {
  title: string
  icon: string
  value: string
}

interface Props {
  selectedProvider: IPaymentProvider
  setSelectedProvider: (p: IPaymentProvider) => void
  paymentProviders: IPaymentProvider[]
}

const PaymentSelect = ({
  selectedProvider,
  setSelectedProvider,
  paymentProviders,
}: Props) => {
  return (
    <Listbox value={selectedProvider} onChange={setSelectedProvider}>
      <div className="relative mt-1">
        <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white rounded-lg shadow-md cursor-pointer focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
          <p className="flex items-center truncate">
            <img
              className="w-24 h-8"
              src={selectedProvider.icon}
              alt={selectedProvider.title}
            />
          </p>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute z-20 w-full mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {paymentProviders.map((provider) => (
              <Listbox.Option
                key={provider.title}
                className={({ active }) =>
                  `relative cursor-default z-10 select-none py-2 pl-10 pr-4 ${
                    active ? 'bg-slate-300' : 'text-gray-900'
                  }`
                }
                value={provider}
              >
                {() => (
                  <div>
                    <img
                      src={provider.icon}
                      className="w-24 h-8"
                      alt={provider.title}
                    />
                  </div>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  )
}

export default PaymentSelect
