import { API_ROUTE_SET_USER_AVATAR } from '@/api/apiRoutes'
import api from '@/lib/api'

interface Body {
  avatar: string
}

export const setUserAvatar = async ({ avatar }: Body) => {
  try {
    const { data } = await api.post(API_ROUTE_SET_USER_AVATAR, {
      avatar,
    })

    return data
  } catch (err) {
    return Promise.reject(err)
  }
}
