import { Popover, Transition } from '@headlessui/react'
import { useSession } from 'next-auth/react'
import useTranslation from 'next-translate/useTranslation'
import { Fragment, useEffect, useState } from 'react'

import { getNotification } from '@/api/notification/getNotification'
import {
  ImageError,
  ImageSuccess,
  ImageWarning,
  notification,
} from '@/res/images'
import { NAMESPACE_COMMON } from '@/res/namespaces'
import { INotification } from '@/types/Common'

import NotificationPopup from './Popups/NotificationPopup'

const Notification = () => {
  const [notifications, setNotifications] = useState<INotification[]>([])
  const [loading, setLoading] = useState(false)
  const [notificationsCount, setNotificationsCount] = useState<null | number>(
    null
  )
  const [isOpen, setIsOpen] = useState<any>(false)
  const [addDescription, setAddDescription] = useState<INotification | null>(
    null
  )
  const { t } = useTranslation(NAMESPACE_COMMON)
  const { data } = useSession()

  const getData = async () => {
    try {
      setLoading(true)
      const res = await getNotification(data?.user.accessToken as string)
      setNotifications(res.results)
      setNotificationsCount(res.results.filter((r) => !r.is_viewed).length)
    } catch (error) {
      return error
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (data?.user.accessToken) {
      getData()
    }
  }, [data?.user.accessToken])

  const descriptionGroup = (item: INotification) => {
    setAddDescription(item)
  }
  const errorTypes: any = {
    warning: (
      <img src={ImageWarning.src} className="w-4 h-4" alt="notification" />
    ),
    success: (
      <img
        src={ImageSuccess.src}
        className="w-[18px] h-[18px]"
        alt="notification"
      />
    ),
    error: <img src={ImageError.src} className="w-4 h-4" alt="notification" />,
    informative: (
      <img src={notification.src} className="w-5 h-5" alt="notification" />
    ),
  }

  const onClose = () => {
    setIsOpen(false)
    setAddDescription(null)
    getData()
  }

  return (
    <div className="mr-1 h-11">
      <Popover className="relative">
        {() => (
          <>
            <Popover.Button className={`outline-none`}>
              <div
                title="Уведомление"
                className="p-2 transition-colors rounded-full cursor-pointer hover:bg-gray-100"
              >
                {notificationsCount !== null ? (
                  <a className="relative">
                    {notificationsCount > 0 && (
                      <div className="absolute flex items-center justify-center w-5 h-5 text-xs font-medium text-white rounded-full -top-2 -right-2 bg-secondary">
                        {notificationsCount}
                      </div>
                    )}
                    <img
                      src={notification.src}
                      className="w-8"
                      alt="notification"
                    />
                  </a>
                ) : null}
              </div>
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute  small:right-0 z-10 right-auto -mt-1.5 w-max max-w-3xl border border-gray-100 rounded-lg">
                <div className="overflow-hidden rounded-lg shadow-lg outline-none">
                  <div className="bg-white">
                    <div className="pr-2 overflow-y-scroll h-80 small:h-96 scrollbar-track-blue-300 scrollbar-thumb-blue-500 scrollbar-thin ">
                      {notifications.length === 0 ? (
                        <div className="mt-4 text-sm text-center w-72 small:w-96 small:text-tiny">
                          {t('no_notifications')}
                        </div>
                      ) : (
                        notifications.map((item) => (
                          <div
                            className="relative flex gap-1 p-2 transition-colors border-b cursor-pointer small:gap-3 small:p-5 hover:bg-gray-100"
                            key={item.id}
                            role="button"
                            onClick={() => descriptionGroup(item)}
                          >
                            {item.is_viewed ? null : (
                              <div className="absolute z-10 w-2 h-2 bg-blue-500 rounded-full right-4" />
                            )}
                            {loading ? 'Loading...' : null}
                            <div>{errorTypes[item.type]}</div>
                            <div
                              onClick={() => setIsOpen(true)}
                              role="button"
                              className={`flex text-sm  items-start w-72 small:w-96 small:text-tiny
                            ${
                              item.is_viewed === true
                                ? 'text-black'
                                : 'text-gray-500'
                            }`}
                            >
                              {item.title}
                            </div>
                          </div>
                        ))
                      )}
                    </div>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
      {addDescription && (
        <NotificationPopup
          isOpen={isOpen}
          onClose={onClose}
          notification={addDescription}
          getData={getData}
        />
      )}
    </div>
  )
}
export default Notification
