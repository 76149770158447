import api from '@/lib/api'
import { INotification } from '@/types/Common'
import { getHeaderToken } from '@/utils/getHeaderToken'

import { API_NOTIFICATION } from '../apiRoutes'
interface Response {
  results: INotification[]
}
export const getNotification = async (token: string) => {
  try {
    const { data } = await api.get<Response>(API_NOTIFICATION, {
      headers: getHeaderToken(token),
    })
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}
