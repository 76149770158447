import api from '@/lib/api'

import { API_NOTIFICATION } from '../apiRoutes'

export const postNotification = async (id: number) => {
  try {
    const { data } = await api.post(`${API_NOTIFICATION}${id}/set_viewed/`)
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}
