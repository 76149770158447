import { Popover, Transition } from '@headlessui/react'
import Link from 'next/link'
import { signOut, useSession } from 'next-auth/react'
import useTranslation from 'next-translate/useTranslation'
import React, { Fragment } from 'react'

import Avatar from '@/components/ui/Avatar'
import { Tooltip } from '@/components/ui/Tooltip'
import ButtonText from '@/components/ui/Typo/ButtonText'
import Caption from '@/components/ui/Typo/Caption'
import { APP_NAME } from '@/res/consts'
import { IconAccountNumber, IconVerifed } from '@/res/icons'
import { NAMESPACE_COMMON } from '@/res/namespaces'
import { ROUTE_PROFILE } from '@/res/routes'

const UserPopover: React.FC = () => {
  const { data } = useSession()
  const { t } = useTranslation(NAMESPACE_COMMON)

  const tooltipContent = (
    <div className="flex flex-col justify-start">
      <Caption color="text-white" weight="font-normal" className="text-left">
        Аккаунт {APP_NAME}
      </Caption>
      <Caption color="text-white" className="text-left" weight="font-normal">
        <span className="text-white">ID:</span> {data?.user.id}
      </Caption>
      <Caption color="text-gray2" weight="font-medium" className="text-left">
        {data?.user.email}
      </Caption>
    </div>
  )

  return (
    <div className="">
      <Popover className="relative">
        {() => (
          <>
            <Popover.Button className={`outline-none`}>
              <Tooltip message={tooltipContent}>
                <div className="p-1 transition-colors border-2 border-transparent cursor-pointer border-primary">
                  {data?.user?.avatar ? (
                    <img
                      src={`${process.env.NEXT_PUBLIC_MEDIA_BASE_URL}${data.user.avatar}`}
                      alt="avatar"
                      className="w-8 h-8 rounded-full small:w-10 small:h-10"
                    />
                  ) : (
                    <div className="w-8 h-8 bg-gray-300 rounded-full small:w-10 small:h-10" />
                  )}
                </div>
              </Tooltip>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute right-0 z-10 -mt-1.5 w-max max-w-md">
                <div className="overflow-hidden rounded-lg shadow-lg outline-none">
                  <div className="bg-white">
                    <div className="pb-3 border-b border-gray-200 px-7 pt-7">
                      <div className="flex justify-center p-1 mx-auto mb-3 transition-colors border-2 border-transparent rounded-full cursor-pointer w-max hover:border-primary">
                        <Avatar
                          avatar={data?.user.avatar as string}
                          size="small"
                        />
                      </div>

                      <Caption weight="font-medium" className="text-center">
                        <span className="flex items-center justify-center">
                          {data?.user.email}
                          {data?.user.is_passport_verified ? (
                            <img
                              className="h-5 ml-2"
                              src={IconVerifed.src}
                              alt="verified"
                            />
                          ) : null}
                        </span>
                        <span className="block mt-2 text-gray-400">
                          {data?.user.is_passport_verified
                            ? t('verified')
                            : t('unverified')}
                        </span>
                      </Caption>
                      {data?.user.account_number ? (
                        <div className="flex items-center justify-center my-2 space-x-2">
                          <img
                            src={IconAccountNumber.src}
                            className="w-6 h-6"
                            alt="account number"
                          />
                          <Caption weight="font-medium">
                            {data.user.account_number}
                          </Caption>
                        </div>
                      ) : null}
                    </div>
                    <div className="pt-4 pb-3 px-7">
                      <div className="flex flex-col justify-center">
                        <Link href={ROUTE_PROFILE}>
                          <a className="mb-3 font-semibold text-center text-primary">
                            {t('go_to_account')}
                          </a>
                        </Link>
                        <button
                          type="submit"
                          onClick={() => signOut()}
                          className="rounded-lg w-full max-w-xs py-2.5 border bg-white border-primary hover:bg-gray-100 active:scale-x-95 outline-none"
                        >
                          <ButtonText size="small" color="text-primary">
                            {t('logout')}
                          </ButtonText>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  )
}

export default UserPopover
